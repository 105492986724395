import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { Injectable, StateKey, makeStateKey } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Product } from './offer.model';

export const PRODUCT_KEY: StateKey<Product> = makeStateKey<Product>('product');

@Injectable({
	providedIn: 'root',
})
export class OfferGuard {
	constructor(private readonly currentOfferFacade: CurrentOfferFacade) {}

	canActivate(
		route: ActivatedRouteSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const id = parseInt(route.paramMap.get('id'));
		if (!id || isNaN(id)) {
			return false;
		}

		this.currentOfferFacade.product$.pipe(take(1)).subscribe((product) => {
			if (!!product && id !== product.id) {
				this.currentOfferFacade.clearCurrentOffer();
			}
			this.currentOfferFacade.fetchMatProduct(id);
		});

		return true;
	}
}
