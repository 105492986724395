import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
	constructor(
		public dialog: MatDialogRef<ContactFormComponent>,
		@Inject(DOCUMENT) private readonly document: Document,
	) {}

	contactForm: FormGroup = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl(''),
		email: new FormControl('', [Validators.email]),
		phone: new FormControl('', [Validators.minLength(9), Validators.maxLength(12), Validators.required]),
		message: new FormControl(''),
		consentCheck: new FormControl(false, [Validators.requiredTrue]),
	});
	showError = false;
	formSend = false;

	ngOnInit(): void {
		if (this.document.querySelector('.offer-detail-page') || this.document.querySelector('.reservation-form'))
			this.document.querySelector('app-contact-form')?.classList.add('zoom-contact');
	}

	protected submitForm(): void {
		this.contactForm.markAllAsTouched();
		if (this.contactForm.invalid) {
			this.showError = true;
			setTimeout(() => (this.showError = false), 3000);
			return;
		}
		let consentMessage = '';
		if (this.contactForm.get('consentCheck')?.valid)
			consentMessage = 'Klient wyraził zgodę na przetwarzanie danych osobowych';
		const xhr = new XMLHttpRequest();
		xhr.open('POST', 'https://pubapi.oskar.com.pl/formLead/', true);
		const data = new FormData();
		data.append('firstName', this.getFormValue('firstName'));
		data.append('lastName', this.getFormValue('lastName'));
		data.append('mail', this.getFormValue('email'));
		data.append('phone', this.getFormValue('phone'));
		data.append('note', `${this.getFormValue('message')}<br>${consentMessage}`);
		data.append('externalId', '');
		xhr.onload = () => {
			if (xhr.readyState === 4 && xhr.status === 200) {
				this.formSend = true;
			} else if (xhr.status !== 200) throw new Error(xhr.statusText);
		};
		xhr.send(data);
	}

	private getFormValue(name: string): string {
		return this.contactForm.get(name)?.value.replace(/\s+/g, '');
	}
}
