import { SellChannel } from '@/common/enum';
import { EnvironmentService } from '@/services/environment/environment.service';
import { Injectable } from '@angular/core';

/**
 * Builder Class which returns URL to the offer page,
 *
 * @Important - You need to chain setProductId method first, else way, the url is going to be broken.
 */
@Injectable()
export class OfferUrlBuilderService {
	private url = '';
	private params = '?';
	constructor(private readonly environmentService: EnvironmentService) {
		this.url = `https://${this.checkDev()}oskar.com.pl/oferta/`;
	}

	setProductId(productId: number): OfferUrlBuilderService {
		this.url = this.url.concat(`${productId}?`);
		return this;
	}

	setProducts(products: number): OfferUrlBuilderService {
		this.params = this.params.includes('products')
			? this.params.replace(/products=.*&/, `products=${products}&`)
			: this.params.concat(`products=${products}&`);
		return this;
	}

	setBoards(boards: number): OfferUrlBuilderService {
		this.params = this.params.includes('boards')
			? this.params.replace(/boards=.*&/, `boards=${boards}&`)
			: this.params.concat(`boards=${boards}&`);
		return this;
	}

	setStops(stops: number): OfferUrlBuilderService {
		this.params = this.params.includes('stops')
			? this.params.replace(/stops=.*&/, `stops=${stops}&`)
			: this.params.concat(`stops=${stops}&`);
		return this;
	}

	setDateRange(dateRange: string): OfferUrlBuilderService {
		this.params = this.params.includes('date_range')
			? this.params.replace(/date_range=.*&/, `date_range=${dateRange}&`)
			: this.params.concat(`date_range=${dateRange}&`);
		return this;
	}

	setRoomType(roomType: number): OfferUrlBuilderService {
		this.params = this.params.includes('room_type')
			? this.params.replace(/room_type=.*&/, `room_type=${roomType}&`)
			: this.params.concat(`room_type=${roomType}&`);
		return this;
	}

	setOwnTransport(ownTransport: boolean): OfferUrlBuilderService {
		this.params = this.params.includes('own_transport')
			? this.params.replace(/own_transport=.*&/, `own_transport=${ownTransport}&`)
			: this.params.concat(`own_transport=${ownTransport}&`);
		return this;
	}

	setAdults(adultsCount: number[]): OfferUrlBuilderService {
		this.params = this.params.includes('adults')
			? this.params.replace(/adults=.*&/, `adults=${adultsCount}&`)
			: this.params.concat(`adults=${adultsCount}&`);
		return this;
	}

	setChildren(childrenCount: number): OfferUrlBuilderService {
		this.params = this.params.includes('children')
			? this.params.replace(/children=.*&/, `children=${childrenCount}&`)
			: this.params.concat(`children=${childrenCount}&`);
		return this;
	}

	setPromotion(promotion: number): OfferUrlBuilderService {
		this.params = this.params.includes('promotions')
			? this.params.replace(/promotions=.*&/, `promotions=${promotion}&`)
			: this.params.concat(`promotions=${promotion}&`);
		return this;
	}

	setNightsMax(night: string): OfferUrlBuilderService {
		this.params = this.params.includes('nights_max')
			? this.params.replace(/nights_max=.*&/, `nights_max=${night}&`)
			: this.params.concat(`nights_max=${night}&`);
		return this;
	}

	setNightsMin(night: string): OfferUrlBuilderService {
		this.params = this.params.includes('nights_min')
			? this.params.replace(/nights_min=.*&/, `nights_min=${night}&`)
			: this.params.concat(`nights_min=${night}&`);
		return this;
	}

	setKinds(kinds: number): OfferUrlBuilderService {
		this.params = this.params.includes('kinds')
			? this.params.replace(/kinds=.*&/, `kinds=${kinds}&`)
			: this.params.concat(`kinds=${kinds}&`);
		return this;
	}

	setLocations(locations: number[]): OfferUrlBuilderService {
		this.params = this.params.includes('locations')
			? this.params.replace(/locations=.*&/, `locations=${locations}&`)
			: this.params.concat(`locations=${locations}&`);
		return this;
	}

	setPromotionOffers(promotionOffers: string): OfferUrlBuilderService {
		this.params = this.params.includes('promotion_offers')
			? this.params.replace(/promotion_offers=.*&/, `promotion_offers=${promotionOffers}&`)
			: this.params.concat(`promotion_offers=${promotionOffers}&`);
		return this;
	}

	setPromotionSells(promotionSells: string): OfferUrlBuilderService {
		this.params = this.params.includes('promotion_sells')
			? this.params.replace(/promotion_sells=.*&/, `promotion_sells=${promotionSells}&`)
			: this.params.concat(`promotion_sells=${promotionSells}&`);
		return this;
	}

	setSellChannels(sellChannels: SellChannel[]): OfferUrlBuilderService {
		this.params = this.params.includes('sell_channels')
			? this.params.replace(/sell_channels=.*&/, `sell_channels=${sellChannels}&`)
			: this.params.concat(`sell_channels=${sellChannels}&`);
		return this;
	}

	clearParams(): OfferUrlBuilderService {
		this.params = '?';
		return this;
	}

	buildParams(): string {
		return this.params;
	}

	build(): string {
		return this.url + this.params;
	}

	private checkDev(): string {
		return this.environmentService.environment?.production ? '' : 'dev.';
	}
}
